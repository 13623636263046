import React from "react"
import { FiX } from "react-icons/fi"
import { useKeyboardEvent, DisableBodyScroll } from "../../utils"
import Portal from "./Portal"

export default function Popup({ children, close, title, className, small }) {
  useKeyboardEvent("Escape", close)

  return (
    <>
      <DisableBodyScroll />
      <Portal>
        <div
          className={`fixed flex justify-center items-center h-screen w-screen top-0 left-0 z-40 cursor-default text-black ${className}`}
        >
          <div
            role="button"
            tabIndex="0"
            onClick={close}
            onKeyDown={close}
            className="fixed h-screen w-screen top-0 left-0 bg-black bg-opacity-25"
          />
          <div
            className={`relative bg-white ${
              small ? "max-w-xl" : "max-w-3xl"
            } w-full h-auto max-h-full lg:max-h-4/5 overflow-y-auto p-6 rounded shadow`}
          >
            {close && (
              <FiX
                role="button"
                tabIndex="0"
                onClick={close}
                onKeyDown={close}
                className="absolute top-0 right-0 m-2 lg:m-4 w-8 h-8 cursor-pointer stroke-1 z-40"
              />
            )}
            {title && (
              <div className="text-xl lg:text-3xl text-blue-dark font-bold mt-2 mb-6 relative leading-tight text-center">
                {title}
              </div>
            )}
            <div className="flex flex-col justify-between items-center flex-wrap text-center min-h-full mb-12 lg:mb-0">
              {children}
            </div>
          </div>
        </div>
      </Portal>
    </>
  )
}

import React from 'react'

export default function Label({ title, children, className, error, locale }) {
  const messages = {
    'pattern': <div>{locale === 'fr' ? 'Mauvais format' : 'Wrong format'}</div>,
    'required': <div>{locale === 'fr' ? 'Champ requis' : 'Required field'}</div>,
    'minLength': <div>{locale === 'fr' ? 'Pas assez de texte' : 'Not enough text'}</div>
  }
  return (
    <label className={`relative block mx-2 lg:mx-5 py-1 text-gray-700 overflow-hidden ${className} ${error && 'text-red-800'}`}>
      <div className="flex justify-between mb-1 font-light text-base">
        {title}
        {error &&
          <div className="mt-2 leading-none font-light text-xs text-red opacity-50 ">
            {error.type ? messages[error.type] : `${error}`}
          </div>
        }
      </div>
      {children}
    </label>
  )
}
